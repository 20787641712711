import React, { useState, useEffect } from "react";
import { Button, Row, Col, Card, Form } from "react-bootstrap";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import { faArrowDown, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import MultipleLineField from "@components/Forms/MulitpleLineField";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import {
  arrangeDataForTable,
  changeAccordions,
  updateSingleService,
} from "../Services/serviceHelpers";
import AccordionCard from "@components/AccordionCard/AccordionCard";
import "./ServiceDescriptions.css";
import { capitalizeWords } from "@utils/helperFunctions";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import FontAwesomeIconTooltipped from "@components/Buttons/FontAwesomeIconTooltipped/FontAwesomeIconTooltipped";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";

function AddServiceDescriptions({
  authorizationCode,
  account_slug,
  projectServiceData,
  languageFields,
  isLoading,
  backToServicesAdded,
  continueProjectCreationWorkFlow,
}) {
  const [oneAccordionIsClosed, setOneAccordionIsClosed] = useState(false);
  const [phases, setPhases] = useState([]);
  const [services, setServices] = useState([]);
  const [subservices, setSubservices] = useState([]);
  const [resources, setResources] = useState([]);
  const [lobs, setLobs] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [globalLanguageField, setGlobalLanguageField] = useState({
    id: 0,
    name: "Service Description",
  });

  const serviceDescriptionInfo = (
    <>
      <div>
        Add descriptions for deliverables and services. Services from Standards
        or Blueprints will have predefined descriptions included.
      </div>
    </>
  );

  useEffect(() => {
    if (
      projectServiceData !== null &&
      Object.keys(projectServiceData).length !== 0 &&
      isLoading === false
    ) {
      let allServices = projectServiceData.data;
      let resources = projectServiceData.resources;
      let subservices = projectServiceData.projectSubservices
        ? projectServiceData.projectSubservices.filter(
            (subservice) => subservice.attributes.active
          )
        : projectServiceData.subservices.filter(
            (subservice) => subservice.attributes.active
          );
      let phases = projectServiceData.phases ? projectServiceData.phases : [];
      let lobs = projectServiceData.lobs ? projectServiceData.lobs : [];
      let services = [];
      let serviceCategories = projectServiceData.serviceCategories
        ? projectServiceData.serviceCategories
        : [];

      const setLanguagesOnServices = (service) => {
        let languages = service.attributes.languages;
        let languagesOnService = Object.entries(languages).map(
          (lang) => lang[0]
        );
        let languageFieldsNotOnServices = languageFields
          .map((lf) => lf.attributes["sow-language"])
          .filter(
            (sowLanguage) =>
              sowLanguage !== "service_description" &&
              !languagesOnService.includes(sowLanguage)
          );
        languageFieldsNotOnServices.forEach((lf) => {
          service.attributes.languages = { ...languages, [lf]: "" };
        });
      };

      allServices.forEach((service) => {
        setLanguagesOnServices(service);
        service.selectedLanguageField = {
          id: 0,
          name: "Service Description",
          languageKey: "service_description",
        };

        // Match service to resource
        let serviceResourceData = service.relationships.resource.data;
        resources.forEach((resource) => {
          if (serviceResourceData && serviceResourceData.id == resource.id) {
            service.resource = resource.attributes.name;
            service.relationships.resource = {
              data: {
                type: "resources",
                id: resource.id,
              },
            };
          }
        });

        const matchSubservices = (relationshipPath) => {
          if (relationshipPath) {
            let subservicesPerService = [];
            let subservicesOnService = relationshipPath.data;
            subservicesOnService.forEach((subserviceOnService) => {
              subservices.forEach((subservice) => {
                if (subserviceOnService.id == subservice.id) {
                  setLanguagesOnServices(subservice);
                  subservice.selectedLanguageField = {
                    id: 0,
                    name: "Service Description",
                    languageKey: "service_description",
                  };
                  subservicesPerService.push(subservice);
                }
              });
            });
            service.subservices = subservicesPerService;
          }
        };

        matchSubservices(service.relationships["project-subservices"]);

        services.push(service);
      });

      let servicesWithoutPhases = services.filter(
        (service) =>
          !service.relationships["project-phase"].data ||
          service.relationships["project-phase"].data.id == null
      );

      if (servicesWithoutPhases.length > 0) {
        phases.push({ id: "noPhase", attributes: { name: "No Phase" } });
        services = services.map((service) => {
          servicesWithoutPhases.forEach((serv) => {
            if (serv.id === service.id) {
              service.relationships["project-phase"] = {
                data: { type: "project-phases", id: "noPhase" },
              };
            }
          });
          return service;
        });
      }

      setServices(Array.from(new Set(arrangeDataForTable(services))));
      setSubservices(Array.from(new Set(subservices)));
      setResources(resources);
      setLobs(lobs);
      setPhases(Array.from(new Set(phases)));
      setServiceCategories(serviceCategories);
    }
  }, [projectServiceData]);

  const changeAllPhaseAccordions = (change) => {
    changeAccordions(".phaseAccordion", change);
  };

  const changeAllServiceAccordions = (change) => {
    changeAccordions(".serviceAccordion", change);
  };

  const languageOptions = languageFields.map((languageField) => {
    return (
      <option accessKey={languageField.id} key={languageField.id}>
        {languageField.attributes.name}
      </option>
    );
  });

  const getLanguageFieldValue = (service) => {
    let languages = service.attributes.languages;
    let languageFieldToDisplay = "";
    if (service.selectedLanguageField.id == 0) {
      languageFieldToDisplay = service.attributes["service-description"]
        ? service.attributes["service-description"]
        : "";
    } else {
      Object.entries(languages).forEach(([key, value]) => {
        if (service.selectedLanguageField.languageKey == key) {
          languageFieldToDisplay = languages[key];
        }
      });
    }
    return languageFieldToDisplay;
  };

  const handleLanguageFieldOnChange = (e, service) => {
    e.persist();
    let languages = service.attributes.languages;
    if (service.selectedLanguageField.id == 0) {
      service.attributes["service-description"] = e.target.value;
    } else {
      Object.entries(languages).forEach(([key, value]) => {
        if (service.selectedLanguageField.languageKey == key) {
          service.attributes.languages[key] = e.target.value;
        }
      });
    }
    setServices(
      services.map((serv) => {
        if (serv.id == e.target.id) {
          return service;
        } else {
          return serv;
        }
      })
    );
  };

  const handleDropDownChange = (e, service) => {
    e.persist();
    let selected = e.target.options.selectedIndex;
    languageFields.forEach((lf) => {
      if (e.target.options[selected].accessKey == lf.id) {
        service.selectedLanguageField = {
          id: lf.id,
          name: lf.attributes.name,
          languageKey: lf.attributes["sow-language"],
        };
      }
    });
    setServices(
      services.map((serv) => {
        if (serv.id == e.target.id) {
          return service;
        } else {
          return serv;
        }
      })
    );
  };

  const getTableData = (tableDataServices) => {
    if (tableDataServices.length === 0) {
      return [];
    }
    // Map over services & return data for ScopeStackTable
    return tableDataServices.map((service) => {
      // Map returns a table row for each service
      return {
        rowClass: "serviceDescriptionRow",
        onClick: null,
        columns: [
          {
            class: "col-4",
            name: (
              <span style={{ display: "flex", alignItems: "center" }}>
                <ControlledDropDown
                  id={service.id}
                  label="Select Description Type"
                  value={service.selectedLanguageField.name}
                  onChange={(e) => {
                    handleDropDownChange(e, service);
                  }}
                  options={languageOptions}
                />
              </span>
            ),
          },
          {
            class: "col-8",
            name: (
              <MultipleLineField
                id={service.id}
                value={getLanguageFieldValue(service)}
                onChange={(e) => handleLanguageFieldOnChange(e, service)}
                label={`Enter ${capitalizeWords(
                  service.selectedLanguageField.name
                )}`}
                rows={3}
                onBlur={(e) => {
                  let serviceToSubmit = Object.assign({}, service);
                  delete serviceToSubmit.selectedLanguageField;
                  delete serviceToSubmit.accordianState;
                  delete serviceToSubmit.links;
                  delete serviceToSubmit.resource;
                  delete serviceToSubmit.subservices;
                  delete serviceToSubmit.relationships;
                  delete serviceToSubmit.name;
                  delete serviceToSubmit.category;
                  delete serviceToSubmit.lob;
                  delete serviceToSubmit.phase;
                  delete serviceToSubmit.tags;
                  delete serviceToSubmit.contextMenuIsOpen;
                  serviceToSubmit.attributes = {
                    "service-description":
                      service.attributes["service-description"],
                    languages: service.attributes.languages,
                  };
                  updateSingleService(
                    serviceToSubmit,
                    account_slug,
                    service.type,
                    authorizationCode
                  );
                }}
              />
            ),
          },
        ],
        actions: null,
      };
    });
  };

  const headerData = {
    rowClass: "serviceDescriptionHeaderRow",
    columns: [
      {
        class: "col-4",
        name: "",
      },
      {
        class: "col-8",
        name: "",
      },
    ],
  };

  return isLoading ? (
    <SmallSpinner />
  ) : (
    <div className="serviceDescriptionsContainer">
      <Card style={{ padding: "20px", backgroundColor: "#fff" }}>
        <Card.Header className="cardHeaderOverride">
          <div className="headerContainer">
            <div className="actionBtns">
              <Button
                className="btnSeawhite"
                onClick={() => backToServicesAdded()}
              >
                Back
              </Button>
              <div className="accordianTitle">
                Service Descriptions{" "}
                <FontAwesomeIconTooltipped
                  icon={faCircleInfo}
                  style={{ color: "#418172" }}
                  toolTipText={serviceDescriptionInfo}
                />
              </div>
            </div>
            <div className={"actionBtns"}>
              <Button
                style={{ marginRight: "10px" }}
                onClick={() => {
                  changeAllPhaseAccordions(
                    oneAccordionIsClosed ? "open" : "close"
                  );
                  changeAllServiceAccordions(
                    oneAccordionIsClosed ? "open" : "close"
                  );
                  setOneAccordionIsClosed(!oneAccordionIsClosed);
                }}
                className="btnSeawhite"
              >
                {oneAccordionIsClosed ? "Expand All" : "Collapse All"}
              </Button>
              <Button
                className="btnSeafoam"
                type="button"
                onClick={() => continueProjectCreationWorkFlow()}
                disabled={false}
              >
                Continue
              </Button>
            </div>
          </div>
          <div
            style={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ color: "#418172", marginBottom: "10px" }}>
              Showing descriptions globally for:
            </div>
            <div>
              {languageFields.map((item, index) => (
                <Button
                  className={
                    index === 0
                      ? "greenButton firstChild"
                      : index === languageFields.length - 1
                      ? "greenButton lastChild"
                      : "greenButton middleChild"
                  }
                  style={
                    index !== 0 && index !== languageFields.length - 1
                      ? { borderRadius: "0" }
                      : index == 0
                      ? {
                          borderTopRightRadius: "0",
                          borderBottomRightRadius: "0",
                        }
                      : index == languageFields.length - 1
                      ? {
                          borderTopLeftRadius: "0",
                          borderBottomLeftRadius: "0",
                        }
                      : {}
                  }
                  key={item.id}
                  onClick={(e) => {
                    let newLanguageField = {
                      id: item.id,
                      name: item.attributes.name,
                      languageKey: item.attributes["sow-language"],
                    };
                    setServices(
                      services.map((s) => {
                        s.selectedLanguageField = newLanguageField;
                        if (s.subservices) {
                          s.subservices.forEach((sub) => {
                            sub.selectedLanguageField = newLanguageField;
                          });
                        }
                        return s;
                      })
                    );
                  }}
                >
                  {item.attributes.name}
                </Button>
              ))}
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          {phases.map((phase) => {
            let servicesOnPhase = [];
            services.forEach((service) => {
              let phaseDataOnService = {};
              phaseDataOnService = service.relationships["project-phase"].data;
              if (phaseDataOnService && phaseDataOnService.id === phase.id) {
                servicesOnPhase.push(service);
              }
            });

            const phaseHeader = (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "85vw",
                  }}
                  className="accordianTitle"
                >
                  {phase.attributes.name}
                </div>
              </div>
            );
            const serviceAccordions = servicesOnPhase.map((service) => {
              let subservicesOnService = [];
              subservicesOnService = service.subservices
                ? service.subservices
                : [];

              let lobName =
                service.relationships.lob && service.relationships.lob.data
                  ? lobs.find(
                      (lob) => lob.id == service.relationships.lob.data.id
                    ).attributes.name
                  : "";
              let serviceCategory = "";
              serviceCategory =
                service.relationships["service-category"] &&
                service.relationships["service-category"].data
                  ? serviceCategories.find(
                      (sc) =>
                        sc.id ==
                        service.relationships["service-category"].data.id
                    ).attributes.name
                  : "";

              const servicesTable = new ScopeStackTable(
                "services",
                headerData,
                getTableData([service]),
                null
              );

              const subserviceAccordion = (subservice) => {
                const subservicesTable = new ScopeStackTable(
                  "subservices",
                  headerData,
                  getTableData([subservice]),
                  null
                );

                const subserviceTable = (
                  <BaseTable
                    className="createFlowSubserviceDescriptionTable"
                    striped={false}
                    hover={false}
                    bordered={false}
                    headerRows={subservicesTable.buildHeaderRows()}
                    dataRows={subservicesTable.buildDataRows()}
                    footerRows={subservicesTable.buildFooterRows()}
                    isLoading={isLoading}
                  />
                );
                return (
                  <AccordionCard
                    key={subservice.id}
                    id={subservice.id}
                    className="subserviceDescriptionAccordion"
                    header={
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "75vw",
                            background: "#fff",
                          }}
                        >
                          <span>
                            <strong className="serviceName">
                              {subservice.attributes.name}
                            </strong>
                          </span>
                        </div>
                      </>
                    }
                    body={subserviceTable}
                    iconOpen={faArrowDown}
                    iconClosed={faArrowRight}
                    closeable={true}
                    iconHexCode="#1c2655"
                    headerBackgroundHexCode="#eee"
                    cardBackgroundHexCode="#eee"
                    cardClass="subserviceAccordion"
                    setAccordianIsOpen={(accordianState) => {
                      services.map((service) => {
                        if (service.id === service.id) {
                          service.accordianState =
                            accordianState === true ? "open" : "closed";
                          if (service.accordianState === "closed") {
                            setOneAccordionIsClosed(true);
                          }
                          return service;
                        } else {
                          return service;
                        }
                      });
                    }}
                    startingAccordionState={"open"}
                  />
                );
              };

              return (
                <div
                  style={{ display: "flex" }}
                  key={service.id}
                  id={service.id}
                >
                  <AccordionCard
                    id={service.id}
                    className="serviceDescriptionAccordion"
                    header={
                      <>
                        {lobName !== "" ? (
                          <div
                            style={{
                              color: "gray",
                              width: "80vw",
                              marginBottom: "5px",
                            }}
                          >
                            {lobName}
                            {serviceCategory ? `: ${serviceCategory}` : ""}
                          </div>
                        ) : null}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span>
                            <strong className="serviceName">
                              {service.attributes.name}
                            </strong>
                          </span>
                        </div>
                      </>
                    }
                    body={
                      <>
                        <BaseTable
                          className="accordionTable createFlowServiceDescriptionTable"
                          striped={false}
                          hover={false}
                          bordered={false}
                          headerRows={servicesTable.buildHeaderRows()}
                          dataRows={servicesTable.buildDataRows()}
                          footerRows={servicesTable.buildFooterRows()}
                          isLoading={isLoading}
                        />
                        {subservicesOnService.map((subservice) =>
                          subserviceAccordion(subservice)
                        )}
                      </>
                    }
                    iconOpen={faArrowDown}
                    iconClosed={faArrowRight}
                    closeable={true}
                    iconHexCode="#1c2655"
                    headerBackgroundHexCode="#eee"
                    cardBackgroundHexCode="#eee"
                    cardClass="serviceAccordion"
                    setAccordianIsOpen={(accordianState) => {
                      services.map((service) => {
                        if (service.id === service.id) {
                          service.accordianState =
                            accordianState === true ? "open" : "closed";
                          if (service.accordianState === "closed") {
                            setOneAccordionIsClosed(true);
                          }
                          return service;
                        } else {
                          return service;
                        }
                      });
                    }}
                    startingAccordionState={"open"}
                  />
                </div>
              );
            });

            return (
              <div style={{ display: "flex" }} key={phase.id} id={phase.id}>
                <AccordionCard
                  className="phaseAccordion"
                  header={phaseHeader}
                  body={[serviceAccordions]}
                  iconOpen={faArrowDown}
                  iconClosed={faArrowRight}
                  closeable={true}
                  iconHexCode="#1c2655"
                  headerBackgroundHexCode="#fff"
                  setAccordianIsOpen={(accordianState) => {
                    phases.map((p) => {
                      if (p.id === phase.id) {
                        p.accordianState =
                          accordianState === true ? "open" : "closed";
                        if (p.accordianState === "closed") {
                          setOneAccordionIsClosed(true);
                        }
                        return p;
                      } else {
                        return p;
                      }
                    });
                  }}
                  startingAccordionState={"open"}
                />
              </div>
            );
          })}
        </Card.Body>
      </Card>
    </div>
  );
}

export default AddServiceDescriptions;
