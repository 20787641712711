import React, { useState, useContext } from "react";
import { Card, Button, Form } from "react-bootstrap";
import SelectSurvey from "./SelectSurvey";
import Survey from "./Survey";
import newSurvey from "./api/newSurvey";
import { AccountContext } from "../../../ProjectsMspa";
import calculate from "./api/calculate";
import getService from "./api/getService";
import API from "../../../../../utils/API/API";
import { handleAlert } from "../../../../../utils/helperFunctions";
import ToastAlert from "../../../../../components/Alerts/ToastAlert/ToastAlert";

const Questionnaire = ({
  setSubpage,
  servicesHaveBeenAdded,
  projectId,
  setServices,
  setResources,
  setSubservices,
  setPhases,
}) => {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const [step, setStep] = useState(1);
  const [questionnaire, setQuestionnaire] = useState({});
  const [questions, setQuestions] = useState([]);
  const [surveyResponses, setSurveyResponses] = useState([]);
  const [surveyId, setSurveyId] = useState(null);
  const [showRecommendations, setShowRecommendations] = useState(false);
  const [recommendations, setRecommendations] = useState([]);
  const [calculatedSurvey, setCalculatedSurvery] = useState(null);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showNoAccessError, setShowNoAccessError] = useState(false);

  const account = useContext(AccountContext);

  const handleCancel = () => {
    if (servicesHaveBeenAdded) {
      setSubpage("servicesAdded");
    } else {
      setSubpage("accordians");
    }
  };

  const handleSurveySubmit = async () => {
    let surveyId = null;
    if (calculatedSurvey) {
      surveyId = calculatedSurvey.data.id;
    }
    const survey = await newSurvey({
      questionnaire,
      responses: surveyResponses,
      account,
      surveyId,
      projectId,
    });

    setSurveyId(calculatedSurvey ? calculatedSurvey.data.id : survey.data.id);

    const calcSurvey = await calculate({
      account,
      surveyId: calculatedSurvey ? calculatedSurvey.data.id : survey.data.id,
    });

    if (calcSurvey.error) {
      setShowFailAlert(true);
      setRecommendations([]);
      setShowRecommendations(true);

      return;
    }

    setCalculatedSurvery(calcSurvey);

    const services = [];
    const resources = [];
    const subservices = [];
    const phases = [];
    let refinements = new Set();

    await Promise.all(
      calcSurvey.data.attributes.recommendations.map(async (recommendation) => {
        if (recommendation["item_type"] === "Task") {
          const { data, included, error } = await getService({
            account,
            serviceId: recommendation["item_id"],
          });

          if (error) {
            setShowRecommendations(false);
            setRecommendations([]);
            setShowNoAccessError(true);
            return;
          }

          if (recommendation.refinements) {
            let foundMatchingRefinement = false;
            for (const refinement of recommendation.refinements) {
              if (refinement.item_id === recommendation.item_id) {
                data.quantity = refinement.quantity;
                foundMatchingRefinement = true;
                break;
              }
            }

            if (!foundMatchingRefinement) {
              data.quantity = recommendation.quantity;
            }
          } else {
            data.quantity = recommendation.quantity;
          }

          services.push(data);
          resources.push(
            ...included.filter((item) => item.type === "resources")
          );

          subservices.push(
            ...included.filter((item) => item.type === "subservices")
          );

          recommendation.refinements.forEach((refinement) => {
            included
              .filter((item) => item.type === "subservices")
              .forEach((subservice) => {
                if (subservice.id == refinement.item_id) {
                  subservice = {
                    ...subservice,
                    attributes: {
                      ...subservice.attributes,
                      quantity: refinement.quantity
                        ? refinement.quantity
                        : subservice.attributes.quantity,
                    },
                  };
                  refinements.add(subservice);
                }
              });
          });

          phases.push(...included.filter((item) => item.type === "phases"));
        }
      })
    );

    setServices(services);
    setResources(resources);
    let newSubservices = Array.from(refinements);
    setSubservices(newSubservices);
    setPhases(phases);

    setShowRecommendations(true);

    setRecommendations(
      services.map((service) => {
        return {
          service,
          subservices: newSubservices.filter((subservice) => {
            return subservice.relationships.service.data.id == service.id;
          }),
        };
      })
    );
  };

  const handleApply = () => {
    calculatedSurvey.data.attributes.recommendations.forEach(
      (recommendation) => {
        API.Put(
          `${apiHost}/${account.slug}/v1/survey-recommendations/${recommendation.id}/apply`,
          account.authorizationCode
        );
      }
    );
    API.Put(
      `${apiHost}/${account.slug}/v1/surveys/${surveyId}/apply`,
      account.authorizationCode
    );
    setSubpage("applyQuestionnaire");
  };

  const renderSteps = () => {
    switch (step) {
      case 1:
        return (
          <SelectSurvey
            setStep={setStep}
            setQuestionnaire={setQuestionnaire}
            setQuestions={setQuestions}
          />
        );
      case 2:
        return (
          <Survey
            questionnaire={questionnaire}
            questions={questions}
            setSurveyResponses={(s) => setSurveyResponses(s)}
            surveyResponses={surveyResponses}
            handleSurveySubmit={handleSurveySubmit}
            showRecommendations={showRecommendations}
            recommendations={recommendations}
            currentSurvey={calculatedSurvey ? calculatedSurvey.data : null}
            showNoAccessError={showNoAccessError}
          />
        );
      default:
        return (
          <SelectSurvey
            setStep={setStep}
            setQuestionnaire={setQuestionnaire}
            setQuestions={setQuestions}
          />
        );
    }
  };

  return (
    <>
      {handleAlert(
        showFailAlert,
        "Something went wrong when calculating surveys",
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      <Card style={{ padding: "10px" }}>
        <Card.Header className="cardHeaderOverride">
          <div className="headerContainer">
            <div className="actionBtns">
              <Button
                style={{ marginRight: "10px" }}
                onClick={() => handleCancel()}
                className="ssButtonBgWhite"
              >
                Cancel
              </Button>
              <h2 style={{ marginLeft: "20px" }}>
                <span className="text24">Add Services from Survey</span>
              </h2>
            </div>
            {step === 2 && (
              <div className="actionBtns">
                <Button
                  style={{ marginRight: "10px" }}
                  className="seafoamBgButton"
                  onClick={handleApply}
                  disabled={!showRecommendations || showNoAccessError || recommendations.length === 0}
                >
                  Continue
                </Button>
              </div>
            )}
          </div>
        </Card.Header>
        <Card.Body style={{ margin: "0 10px" }}>{renderSteps()}</Card.Body>
      </Card>
    </>
  );
};

export default Questionnaire;
