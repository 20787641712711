import React, { useCallback, useEffect, useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import Select from "react-select";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import "./CustomService.css";
import loadProjectPhases from "./api/loadProjectPhases";
import loadCategories from "./api/loadCategories";
// import loadRateType from "./api/loadRateType";
import loadSubservices from "./api/loadSubservices";
import getNewCustomService from "./api/getNewCustomService";
import Select2 from "@components/Forms/Select2/Select2";

const CustomService = ({
  authorizationCode,
  account_slug,
  subpage,
  setSubpage,
  setCustomServices,
  servicesHaveBeenAdded,
  setSubservicesOnCustomServices,
  setPhasesOnCustomServices,
  projectId,
  resources,
  rateType,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [phases, setPhases] = useState([{ label: "-", value: "" }]);
  const [categories, setCategories] = useState([{ label: "-", value: "" }]);
  const [resourcesForDropdown, setResourcesForDropdown] = useState([
    { label: "-", value: "" },
  ]);
  // const [rateType, setRateType] = useState("");

  const headerData = {
    rowClass: "serviceHeaderRow",
    columns: [
      {
        class: "col-2",
        name: "Phase",
      },
      {
        class: "col-2",
        name: "Service Category",
      },
      {
        class: "col-4",
        name: "Service Name",
      },
      {
        class: "col-1",
        name: "Quantity",
      },
      {
        class: "col-1",
        name: `${rateType}`,
      },
      {
        class: "col-2",
        name: "Resource",
      },
    ],
  };

  const buildCategoryOptions = () => {
    let options = new Set();
    let categoryGroups = new Set();
    categories.forEach((category) => {
      categoryGroups.add(category.attributes["nested-name"].split(" > ")[0]);
    });

    Array.from(categoryGroups).forEach((categoryGroup) => {
      options.add({
        label: categoryGroup,
        options: categories
          .filter((category) => {
            return (
              category.attributes["nested-name"].split(" > ")[0] ===
              categoryGroup
            );
          })
          .map((category) => {
            return {
              label: category.attributes["nested-name"],
              value: category.id,
            };
          }),
      });
    });

    return Array.from(options);
  };

  const buildTableRowData = (index) => {
    return {
      rowClass: "serviceRow",
      columns: [
        {
          class: "col-2",
          name: (
            <Select
              id={`phase-${index}`}
              key={`phase-${index}`}
              defaultValue={{
                label: phases[0].label,
                value: phases[0].value,
              }}
              onChange={(e) => {
                const serviceToUpdate = services[index];
                serviceToUpdate.phase = e.value;
                setServices(
                  services.map((service, i) => {
                    if (i === index) {
                      return serviceToUpdate;
                    }
                    return service;
                  })
                );
              }}
              options={phases.map((phase) => {
                return { label: phase.label, value: phase.value };
              })}
            />
          ),
        },
        {
          class: "col-2",
          name: (
            <Select
              id={`category-${index}`}
              key={`category-${index}`}
              defaultValue={{
                label: categories[0].label,
                value: categories[0].value,
              }}
              onChange={(e) => {
                const serviceToUpdate = services[index];
                serviceToUpdate.category = e.value;
                setServices(
                  services.map((service, i) => {
                    if (i === index) {
                      return serviceToUpdate;
                    }
                    return service;
                  })
                );
              }}
              options={buildCategoryOptions()}
            />
          ),
        },
        {
          class: "col-4",
          name: (
            <Form.Control
              required={true}
              type="text"
              placeholder="Service Name"
              style={{ height: "calc(1.5em + 0.75rem + 5px)" }}
              onChange={(e) => {
                const serviceToUpdate = services[index];
                serviceToUpdate.name = e.target.value;
                setServices(
                  services.map((service, i) => {
                    if (i === index) {
                      return serviceToUpdate;
                    }
                    return service;
                  })
                );
              }}
            />
          ),
        },
        {
          class: "col-1",
          name: (
            <Form.Control
              required={true}
              type="number"
              min={1}
              step={1}
              defaultValue={1}
              style={{ height: "calc(1.5em + 0.75rem + 5px)" }}
              onChange={(e) => {
                const serviceToUpdate = services[index];
                serviceToUpdate.qty = e.target.value;
                setServices(
                  services.map((service, i) => {
                    if (i === index) {
                      return serviceToUpdate;
                    }
                    return service;
                  })
                );
              }}
            />
          ),
        },
        {
          class: "col-1",
          name: (
            <Form.Control
              required={true}
              type="number"
              min={0}
              step={0.1}
              defaultValue={0}
              style={{ height: "calc(1.5em + 0.75rem + 5px)" }}
              onChange={(e) => {
                const serviceToUpdate = services[index];
                serviceToUpdate.loe = e.target.value;
                setServices(
                  services.map((service, i) => {
                    if (i === index) {
                      return serviceToUpdate;
                    }
                    return service;
                  })
                );
              }}
            />
          ),
        },
        {
          class: "col-2",
          name: (
            <Select
              id={`resource-${index}`}
              key={`resource-${index}`}
              defaultValue={{
                label: resourcesForDropdown[0].label,
                value: resourcesForDropdown[0].value,
              }}
              onChange={(e) => {
                const serviceToUpdate = services[index];
                serviceToUpdate.resource = e.value;
                setServices(
                  services.map((service, i) => {
                    if (i === index) {
                      return serviceToUpdate;
                    }
                    return service;
                  })
                );
              }}
              options={resourcesForDropdown.map((resource) => {
                return { label: resource.label, value: resource.value };
              })}
            />
          ),
        },
      ],
    };
  };

  const buildTableData = () => {
    const tableData = [];
    services.forEach((_, i) => {
      tableData.push(buildTableRowData(i));
    });
    return tableData;
  };

  const servicesTable = new ScopeStackTable(
    "services",
    headerData,
    buildTableData(),
    null
  );

  const handleCancel = () => {
    if (servicesHaveBeenAdded) {
      setSubpage("servicesAdded");
    } else {
      setSubpage("accordians");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newServicesPromise = services.map(async (service) => {
      const newService = await getNewCustomService({
        service,
        projectId,
        rateType,
        account_slug,
        authorizationCode,
      });
      return newService;
    });

    const newServices = await Promise.all(newServicesPromise);

    setCustomServices(newServices.map((service) => service.data));
    setSubpage("servicesAdded");
  };

  const handleAdd = () => {
    setServices([
      ...services,
      {
        phase: phases[0].value,
        category: categories[0].value,
        name: "",
        loe: 0,
        qty: 1,
        resource: resourcesForDropdown[0].value,
      },
    ]);
  };

  useEffect(() => {}, [services]);

  const fetchData = useCallback(async () => {
    const phasesData = await loadProjectPhases({
      account_slug,
      authorizationCode,
      projectId,
    });
    const categoriesData = await loadCategories({
      account_slug,
      authorizationCode,
    });

    const subservices = await loadSubservices({
      account_slug,
      authorizationCode,
    });

    setPhases(
      phasesData.data.map((phase) => {
        return { label: phase.attributes.name, value: phase.id };
      })
    );

    setCategories(
      categoriesData.data.filter(
        (category) => category.attributes["has-parent?"] == true
      )
    );
    setResourcesForDropdown(
      resources.map((resource) => {
        return { label: resource.attributes.name, value: resource.id };
      })
    );

    setSubservicesOnCustomServices(subservices);
    setPhasesOnCustomServices(phasesData.data);

    setServices([
      {
        phase: phasesData.data[0].id,
        category: categoriesData.data.filter(
          (category) => category.attributes["has-parent?"] == true
        )[0].id,
        name: "",
        loe: 0,
        qty: 1,
        resource: resources[0].id,
      },
    ]);

    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Card style={{ padding: "10px" }}>
      <Card.Header className="cardHeaderOverride">
        <div className="headerContainer">
          <div className="actionBtns">
            <Button
              style={{ marginRight: "10px" }}
              onClick={() => handleCancel()}
              className="ssButtonBgWhite"
            >
              Cancel
            </Button>
            <h2 style={{ marginLeft: "20px" }}>
              <span className="text24">Add Custom Services</span>
            </h2>
          </div>
          <div className="actionBtns">
            <Button
              style={{ marginRight: "10px" }}
              className="seafoamBgButton"
              type="submit"
              form="customServicesForm"
            >
              Continue
            </Button>
          </div>
        </div>
      </Card.Header>
      <hr />
      <Card.Body>
        <div
          style={{ fontSize: "20px", marginBottom: "30px" }}
          className="accordianSubtitle"
        >
          Add custom services
        </div>
        <Form id="customServicesForm" onSubmit={handleSubmit}>
          <Card
            style={{
              padding: "20px",
              background: "rgb(238, 238, 238)",
              border: "1px solid rgb(222, 226, 230)",
            }}
          >
            <Card.Body>
              <BaseTable
                className={""}
                striped={false}
                hover={false}
                bordered={false}
                headerRows={servicesTable.buildHeaderRows()}
                dataRows={servicesTable.buildDataRows()}
                footerRows={servicesTable.buildFooterRows()}
                isLoading={isLoading}
              />
            </Card.Body>
            <Button
              style={{ width: "fit-content" }}
              onClick={() => handleAdd()}
              className="seafoamBgButton"
            >
              Add Another Service
            </Button>
          </Card>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default CustomService;
